<template>
  <div class="sign-in">
    <div class="container">
      <h4 class="text-center title">Log In</h4>
      <v-form @submit="onSubmit">
        <div class="errors mb-12" v-if="errors.length">
          <b>Please correct the following error(s):</b>
          <ul>
            <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
          </ul>
        </div>
        <v-text-field id="input-1" v-model="form.email" type="email" required placeholder="Enter email"
          label="Email address:"></v-text-field>

        <v-text-field type="password" id="input-2" v-model="form.password" required placeholder="Enter password"
          label="Your Password:"></v-text-field>

        <v-btn type="submit" color="primary">Login</v-btn>
      </v-form>
      <div class="mt-4">
        Login issue?
        <a href="/reset-password-request">Reset your password</a>
      </div>
      <div class="mt-5 text-center">
        Don't have an account yet?
        <a href="https://salesgrowth.com/gap-selling-course/">Register</a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.errors ul {
  margin: auto;

  li {
    list-style-type: none;
  }
}

.container {
  max-width: 500px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
  padding: 25px;
  margin: 30px auto;
  background-color: white;
}
</style>

<script>
import SIGN_IN from "../graphql/SignIn.gql";
import router from "../router";

export default {
  data() {
    return {
      isInit: false,
      isSignIn: false,
      errors: [],
      form: {
        email: "",
        password: "",
      },
      show: true,
    };
  },
  mounted() {
    this.$store.dispatch("auth/signin", { authUser: null });
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault();

      this.errors = [];
      try {
        const signInResult = await this.$apollo.mutate({
          mutation: SIGN_IN,
          variables: {
            email: this.form.email,
            password: this.form.password,
          },
        });
        this.$store.dispatch("auth/signin", {
          authUser: signInResult.data.authUser,
        });
        this.$store.dispatch("auth/updateEditMode", { editMode: false });
        if (window.analytics) {
          window.analytics.identify(signInResult.data.authUser.email, {
            name: signInResult.data.authUser.firstName + " " + signInResult.data.authUser.lastName,
            email: signInResult.data.authUser.email,
            authUser_id: signInResult.data.authUser._id,
          });
        }
        // if (
        //   signInResult.data.authUser.account.signUpActiveStep < 3 &&
        //   signInResult.data.authUser.account.paymentPlanId != "demo" &&
        //   signInResult.data.authUser.account.selectedPlanId != "po_internal"
        // ) {
        //   // router.push({ name: "signup" });
        //   confirm("There is an issue with your account. Please update your information.");
        //   router.push("/settings?tabName=plan&i=payment");
        // } else {
        if (
          this.$route.query.lasturl &&
          this.$route.query.lasturl.indexOf("/course-list-type") == -1
        ) {
          window.location.href = decodeURIComponent(this.$route.query.lasturl);
        } else {
          router.push({ name: "course-list-type", query: { checkpickup: true } });
        }
        // }
      } catch (error) {
        this.errors.push(error.toString().replace("Error: GraphQL error: ", "")); // Vue doesn't like non-native types as keys, hence string
        this.$store.dispatch("auth/signin", { authUser: null });
      }
    },
  },
};
</script>
