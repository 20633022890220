<template>
  <div class="sign-up">
    <div class="container" v-if="isLoaded">
      <!-- end step 2 -->
      <!--  step 2 -->
      <!-- {{this.auth.authUser.account?.signUpActiveStep}} -->
      <div v-if="activeTab == 0" class="container">
        <div class="text-center">
          <img :width="'100%'" class="step-img" :src="'/img/plan-register-payment-123-1.jpg'" />
        </div>
        <!-- <div class="row pt-12" v-if="plans">
          <v-col
            class="px-0 mx-0"
            v-for="(plan, i) in plans.filter((x) => x.isSelectable)"
            :key="plan._id"
            :md="i == 0 || i == 2 ? 4 : 8"
            cols="12"
          >
            <div @click="goToPlan(plan)">
              <v-row class="mx-auto">
                <v-col cols="12" class="mt-3">
                  <h5>&nbsp;</h5>
                  <div class="plan-card single ml-auto mr-md-1 mr-auto active pa-5">
                    <div class="top">
                      <div class="plan-name">
                        {{ plan.name }}
                      </div>
                      <div class="d-flex text-center justify-content-center cost my-3">
                        <div class="amount">
                          {{ i == 0 ? "" : "Starting at " }}
                          {{ toCurrency(plan.paymentAmountTotal) }}
                        </div>

                        <div class="per">{{ plan.paymentTerm }}</div>
                      </div>
                      <p v-if="plan.description" class="description">
                        {{ plan.description }}
                      </p>

                      <div v-if="plan.isCurrent">Your Current Plan</div>
                    </div>

                    <div class="d-flex my-4">
                      <div
                        class="seats mr-6"
                        v-for="seat in plan.includedSeats"
                        v-bind:key="seat.name"
                      >
                        <i :class="seat.icon"></i>
                        <span v-if="seat.details">{{ " " + seat.details }}</span>
                        <span v-if="seat.name" class="pl-2 mt-2">{{ seat.name }}</span>
                      </div>
                    </div>

                    <div class="includes">
                      <div style="font-size: 12px" class="primary--text mb-2">INCLUDES</div>
                      <div
                        class="mr-6 mb-2"
                        v-for="(included, pdindex) in getPlanDetails(plan.planId).includes"
                        v-bind:key="pdindex"
                      >
                        <i class="primary--text fas fa-solid fa-check"></i>
                        <span class="pl-3">{{ included }}</span>
                      </div>
                    </div>
                    <div class="not-includes mt-6" v-if="getPlanDetails(plan.planId).notIncludes">
                      <hr />
                      <div
                        class="mr-6 mb-2 mt-3"
                        v-for="(notIncluded, pdindex) in getPlanDetails(plan.planId).notIncludes"
                        v-bind:key="pdindex"
                      >
                        <i class="primary--text fas fa-solid fa-xmark"></i>
                        <span class="pl-3">{{ notIncluded }}</span>
                      </div>
                    </div>
                    <div class="checkout-btn">
                      <v-btn
                        v-if="plan.action == 'contact_sales'"
                        color="primary mx-auto buy-now"
                        target="_blank"
                      >
                        Contact Sales
                      </v-btn>
                      <v-btn
                        v-if="plan.action == 'online_purchase'"
                        color="primary mx-auto buy-now"
                      >
                        Select Plan
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </div> -->
      </div>
      <div v-if="activeTab == 1">
        <div class="text-center">
          <a href="https://salesgrowth.com/gap-selling-course-pricing/">
            <img v-if="selectedPlanId != 'demo'" class="step-img" :src="'/img/plan-register-payment-123-2_1.jpg'" />
          </a>
          <img class="step-img" :src="selectedPlanId == 'demo'
            ? '/img/plan-register-payment-123-demo-2.jpg'
            : '/img/plan-register-payment-123-2.jpg'
            " />
        </div>
        <v-form ref="form" id="profile" @submit="onSubmit">
          <!-- <h5>User Profile</h5> -->
          <v-row class="justify-center">
            <v-col md="5" cols="12" class="mt-8">
              <div>
                <h3>Order Summary</h3>
                <v-card class="pt-2 pb-5 px-8 mt-5">
                  <v-row class="order-summary order-summary-row mb-3">
                    <v-col cols="3"> </v-col>
                    <v-col cols="9" class="text-right"> </v-col>
                    <v-col cols="3"> PRODUCT </v-col>
                    <v-col cols="9" class="text-right">
                      <div class="text-capitalize">
                        {{
                          selectedPlan.productType == "series"
                            ? "Gap Plus Subscription"
                            : "Self-Paced Gap Selling Training"
                        }}
                      </div>
                    </v-col>
                    <v-col cols="3"> PLAN </v-col>
                    <v-col cols="9" class="text-right">
                      <div class="text-capitalize">
                        {{ selectedPlan.name ? selectedPlan.name.toLowerCase() : "" }}
                      </div>
                    </v-col>
                    <v-col cols="3"> PRICE </v-col>
                    <v-col cols="9" class="text-right">
                      <div v-if="selectedPlan && selectedPlan.paymentAmountTotal" class="cost">
                        <div :class="coupon ? 'strike' : ''" class="amount">
                          {{ toCurrency(selectedPlan.paymentAmountTotal) }}
                        </div>
                        <div v-if="coupon" class="amount">
                          {{ toCurrency(selectedPlan.paymentAmountTotalDiscounted) }}
                        </div>
                        <div class="per">{{ selectedPlan.paymentTerm }}</div>
                        <!-- <div>(for rest of year)</div> -->
                        <div><a href="https://gapsellingonlinetraining.com/terms-and-conditions/" target="_blank">see
                            terms of service</a></div>
                        <div> {{ selectedPlan.productType == "series" ? '*minimum 12 month commitment ' : '' }} </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </v-col>

            <v-col cols="12" md="7" class="mt-5">
              <v-row class="justify-center">
                <v-col cols="12" :md="6">
                  <v-text-field label="First Name:" id="input-firstname" v-model="firstName"
                    :rules="[(v) => !!v || 'First Name is required']" type="text" required
                    placeholder="Your first name"></v-text-field>
                </v-col>
                <v-col cols="12" :md="6">
                  <v-text-field label="Last Name:" id="input-lastname" v-model="lastName"
                    :rules="[(v) => !!v || 'Last Name is required']" required
                    placeholder="Your last name"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center">
                <v-col cols="12" :md="12">
                  <v-text-field class="mt-3" label="Email address:" id="input-1" v-model="email"
                    :rules="[(v) => !!v || 'Email is required']" type="email" required
                    placeholder="Your email"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center">
                <v-col cols="12" :md="6" class="pt-9">
                  <v-text-field label="Password ( > 8 charaters ):" id="input-2" type="password" v-model="password"
                    :rules="passwordRules" required placeholder="Your password"></v-text-field>
                </v-col>
                <v-col cols="12" :md="6" class="pt-9">
                  <v-text-field type="password" v-model="newPassword2" :rules="passwordRules" required
                    placeholder="Enter password again " label="Your Password ( > 8 charaters ):"
                    autocomplete="new-password"></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-9">
                  <v-alert :value="hasSignupError" class="font-weight-bold red--text text-h7 mt-2">{{ signupError
                    }}</v-alert>

                  <div class="d-flex justify-center mt-12">
                    <v-btn x-large type="submit" color="primary">
                      <span v-if="selectedPlanId != 'demo'"> Continue to Payment &gt;</span>
                      <span v-if="selectedPlanId == 'demo'"> Continue to Training &gt;</span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <!-- end step 2 -->

      <!--  step 3 -->
      <div v-if="activeTab == 2">
        <div class="text-center">
          <img :width="selectedPlanId == 'demo' ? '40%' : '100%'" class="step-img" :src="selectedPlanId == 'demo'
            ? '/img/plan-register-payment-123-demo-3.jpg'
            : '/img/plan-register-payment-123-3.jpg'
            " />
        </div>
        <v-row justify="center" class="order-summary">
          <v-col md="5" cols="12" class="mt-8">
            <div>
              <h3>Order Summary</h3>
              <v-card class="pt-2 pb-5 px-8 mt-5" v-if="!selectedAddOn">
                <v-row class="order-summary-row mb-3">
                  <v-col cols="3"> </v-col>
                  <v-col cols="9" class="text-right"> </v-col>
                  <v-col cols="3"> PRODUCT </v-col>
                  <v-col cols="9" class="text-right">
                    <div class="text-capitalize">
                      {{
                        selectedPlan.productType == "series"
                          ? "Gap Plus Subscription"
                          : "Self-Paced Gap Selling Training"
                      }}
                    </div>
                  </v-col>
                  <v-col cols="3"> PLAN </v-col>
                  <v-col cols="9" class="text-right">
                    <div class="text-capitalize">
                      {{ selectedPlan.name ? selectedPlan.name.toLowerCase() : "" }}
                    </div>
                  </v-col>
                  <v-col cols="3"> PRICE </v-col>
                  <v-col cols="9" class="text-right">
                    <div v-if="selectedPlan && selectedPlan.paymentAmountTotal" class="cost">
                      <div :class="coupon ? 'strike' : ''" class="amount">
                        {{ toCurrency(selectedPlan.paymentAmountTotal) }}
                      </div>
                      <div v-if="coupon" class="amount">
                        {{ toCurrency(selectedPlan.paymentAmountTotalDiscounted) }}
                      </div>
                      <div class="per">{{ selectedPlan.paymentTerm }}</div>
                      <!-- <div>(for rest of year)</div> -->
                      <div><a href="https://gapsellingonlinetraining.com/terms-and-conditions/" target="_blank">see
                          terms of service</a></div>
                    </div>
                  </v-col>

                  <!-- <div class="plan-card single ml-auto mr-md-1 mr-auto active">
                    <div class="top">
                      <div class="header-title mb-2">
                        {{ selectedPlan.name }}
                      </div>
                      <div class="header-title">{{ selectedPlan.description }}</div>
                      <div
                        v-if="selectedPlan && selectedPlan.paymentAmountTotal"
                        class="d-flex text-center justify-content-center cost"
                      >
                        <div :class="coupon ? 'strike' : ''" class="amount">
                          {{ toCurrency(selectedPlan.paymentAmountTotal) }}
                        </div>
                        <div v-if="coupon" class="amount">
                          {{ toCurrency(selectedPlan.paymentAmountTotalDiscounted) }}
                        </div>

                        <div class="per">/{{ selectedPlan.paymentTerm }}</div>
                      </div>
                    </div>
                  </div> -->
                </v-row>
              </v-card>
            </div>
            <div v-if="selectedPlan.addOnIds && selectedPlan.addOnIds.length > 0">
              <h3 class="mt-8 text-center primary--text">DOUBLE THE VALUE!</h3>
              <v-card class="gap-plus-card px-5 pb-5 mt-2">
                <div class="d-flex justify-space-between">
                  <div class="pt-5 mr-5">
                    <div class="d-flex">
                      <div class="gap-plus-logo">
                        <img src="/img/gap-plus-logo.svg" />
                      </div>
                    </div>
                    <div>(<i>Keep the value coming</i>)</div>
                  </div>
                  <div class="pt-1">
                    <v-checkbox dark @change="setAddOn(selectedPlan, selectedPlan.isGapPlus)"
                      v-model="selectedPlan.isGapPlus" color="white"></v-checkbox>
                  </div>
                </div>
                <div class="mt-8">
                  Regular monthly content targeting the most pressing sales issues delivered by
                  Keenan and other Gap selling experts.
                </div>
              </v-card>
              <div class="mt-6">
                <div class="mb-4">Total Payment</div>
                <v-row>
                  <v-col cols="10" class="text-capitalize">Self Paced Online Course Plan:
                    {{ selectedPlan.name ? selectedPlan.name.toLowerCase() : "" }}
                  </v-col>
                  <v-col class="text-right" v-if="selectedPlan.planId == '1' && selectedAddOn">
                    included
                  </v-col>
                  <v-col class="text-right" v-if="selectedPlan.planId != '1'">{{
                    toCurrency(selectedPlan.paymentAmountTotal) }}
                    <div class="per">{{ selectedPlan.paymentTerm }}</div>
                  </v-col>
                </v-row>
                <v-row v-if="selectedAddOn">
                  <v-col cols="9">
                    <span> Including GAP PLUS+</span>
                  </v-col>
                  <v-col class="text-right">
                    {{ toCurrency(selectedAddOn.paymentAmount) }}
                    <div class="per">{{ selectedAddOn.paymentTerm }}</div>
                    <div class="per" v-if="selectedPlan.planId == '1'">(billed annually)</div>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <a href="https://gapsellingonlinetraining.com/terms-and-conditions/" target="_blank">see
                      terms of service</a>
                  </v-col>
                </v-row>
                <hr class="mt-3 mb-3" />
                <v-row>
                  <v-col> Total</v-col>
                  <v-col class="text-right">{{
                    selectedAddOn
                      ? toCurrency(
                        selectedPlan.planId == "1"
                          ? selectedAddOn.paymentAmountTotal
                          : selectedPlan.paymentAmountTotal + selectedAddOn.paymentAmountTotal
                      )
                      : toCurrency(selectedPlan.paymentAmountTotal)
                  }}
                    <div class="per">
                      {{ selectedAddOn ? selectedAddOn.paymentTerm : selectedPlan.paymentTerm }}
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
          <v-col md="5" cols class="mt-3">
            <h3 class="mt-5">Payment</h3>
            <p>All transactions are secure and encrypted.</p>

            <div class="coupon-code mt-5 mx-auto">
              <div class="d-flex justify-content-center" id="input-group-coupon-code-wrapper"
                :class="getCodeError ? 'invalid' : ''">
                <v-text-field id="input-couponCode" v-model="couponCode" type="text" placeholder="Enter Coupon Code"
                  :error="getCodeError" @keydown.enter.native="applyCouponCode(couponCode)"></v-text-field>

                <v-btn @click="applyCouponCode(couponCode)" class="mx-auto py-5" color="primary" small
                  :disabled="!couponCode">
                  Apply
                  <br />Discount Code
                </v-btn>
              </div>
              <div class="small red--text" v-if="getCodeError">
                {{ getCodeError }}
              </div>
              <div class="small" v-if="coupon">
                Your
                <span v-if="coupon.percent_off">{{ coupon.percent_off }}%</span>
                <span v-if="coupon.amount_off">${{ Math.floor(coupon.amount_off / 100) }}</span>
                {{ coupon.name }} discount has been applied!
              </div>
            </div>
            <v-form id="payment" @submit="onSubmit">
              <div class="mt-5 ml-auto ml-md-1 mr-auto">
                <Payment ref="payment" v-model="token" @updateTokenError="tokenUpdatedError"></Payment>

                <v-alert :value="hasSignupError" class="font-weight-bold red--text text-h7 mt-2">{{
                  signupError
                  }}</v-alert>
              </div>
              <small class="grey--text pt-5" v-if="selectedPlanId == 'gap_plus_individual_learner'">
                <v-checkbox v-model="gapPlusAcknowledge"
                  label="I acknowledge that Gap+ requires a minimum 12-month subscription commitment."></v-checkbox>
              </small>
              <div class="d-flex justify-center mt-12 mb-2">
                <v-btn
                  :disabled="isSubmitting || (!gapPlusAcknowledge && selectedPlanId == 'gap_plus_individual_learner')"
                  x-large type="submit" color="primary">Purchase
                  &gt;</v-btn>
              </div>
              <small class="grey--text pt-2">
                by clicking PURCHASE you agree to our
                <a target="_blank" href="https://gapsellingonlinetraining.com/terms-and-conditions/">Terms and
                  Conditions</a> .
              </small>
            </v-form>
          </v-col>
        </v-row>
      </div>
      <!-- end step 3 -->
      <!--  step 4 -->
      <div v-if="activeTab == 3" class="container">
        <h2 class="mt-12 text-center primary--text">Congrats!</h2>
        <p></p>
        <div class="text-center">
          <v-btn @click="letsGo()" class="mx-auto mt-3" x-large color="primary"> Let's Go </v-btn>
        </div>
      </div>
      <!--  end step 4 -->
    </div>
    <v-dialog width="500px" v-model="showAddOnCheck">
      <v-card class="pa-12">
        <div class="text-center">
          <h2 class="text-center b-2">Are you sure?</h2>
          <div class="text-center mb-5">Gap + is the better deal.........</div>
          <v-btn class="mr-4" color="primary" outlined @click="skipGapPlus()"> No Thanks</v-btn>
          <v-btn class="ml-4" color="primary" @click="addGapPlus()">Add GAP+</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.step-img {
  max-width: 100%;
}

.strike {
  text-decoration: line-through;
  color: grey;
}

.sign-up {
  font-size: 20px;
}

#plan {
  font-size: 16px;
}

#profile {
  // max-width: 1000px;
}

section {
  margin-bottom: 80px;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.quote {
  // max-width: 80%;
}

#video-wrapper {
  margin: auto;
  width: 500px;
  // max-width: 900px;
}

video {
  width: 100% !important;
  height: auto !important;
}

.testimonials {
  background-color: var(--v-primary-base);
}

// .container {
//   max-width: 1500px;
//   margin: auto;
// }
#profile {
  margin: auto;
}

.seats {
  margin-top: 0px;
}

.paymentTerms {
  color: white;
  margin-top: -20px;
  margin-bottom: 20px;
}

.v-select__selection {
  color: white;
}

.plan-wrapper {
  margin: auto;
  max-width: 380px;
}

.radio-group {
  width: 100%;
}

.radio-btn {
  max-width: 50px;
  margin: auto;
}

.google-signin-btn {
  svg {
    margin-right: 5px;
  }
}

.text-size-2 {
  font-size: 2rem;
}

.header {
  color: var(--v-primary-base);
  font-weight: bold;
  text-align: center;
}

.steps {
  margin: auto;
  max-width: 800px;

  .step {
    text-align: center;
    color: #939393;
    width: 110px;
    font-size: 18px;

    // &.done {
    //   color: var(--v-primary-base);
    // }
    &.current {
      color: var(--v-primary-base);
      // background-color: var(--v-primary-base);
      // border-radius: 50%;
      display: inline-block;
    }
  }
}

.password-rules ul li {
  font-size: 14px;
}

.plan-header {
  width: 300px;
  color: #cb4f53;
}

.per {
  font-size: 11px;
  color: black;
}

.plan-card {
  position: relative;

  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  border: 1px #707070 solid;
  height: 520px;

  .top {
    .pill {
      padding: 10px 0;
      position: absolute;
      top: 215px;
      left: 0px;
      right: 0px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;

      .buy-now {
        width: 80%;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .header-title {
      font-size: 22px;
      text-align: center;
      text-transform: capitalize;
    }

    .money-sign {
      font-size: 18px;
      padding-top: 10px;
    }

    .amount.strike {
      font-size: 25px;
      padding-top: 10px;
      padding-right: 8px;
    }

    .amount {
      font-size: 16px;
    }

    .per {
      padding-top: 0px;
    }
  }

  .plan-name {
    font-size: 18px;
  }

  .description {
    font-size: 14px;
    height: 90px;
  }

  .seats {
    font-size: 14px;
  }

  .includes,
  .not-includes {
    font-size: 14px;
  }

  .checkout-btn {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
  }

  // &.standard {
  //   .top {
  //     background-color: #9eacaf;
  //     .pill {
  //       color: #9eacaf;
  //     }
  //   }
  // }
}

#input-group-coupon-code-wrapper.invalid {
  /* also need animation and -moz-animation */
  -webkit-animation: shake 0.5s linear;
}

.header-desc {
  font-size: 14px;
  min-height: 65px;
}

.order-summary {
  font-size: 14px;
}

.order-summary-row>div {
  border-bottom: 1px black solid;
  padding-top: 20px;
  padding-bottom: 18px;
}

.gap-plus-card {
  background-color: var(--v-primary-base);
  color: white;
}

.gap-plus-logo {
  height: 40px;
}

.gap-plus-logo img {
  height: 60px;
  margin-top: -15px;
  margin-left: -13px;
}

/* also need keyframes and -moz-keyframes */
@-webkit-keyframes shake {

  8%,
  41% {
    -webkit-transform: translateX(-10px);
  }

  25%,
  58% {
    -webkit-transform: translateX(10px);
  }

  75% {
    -webkit-transform: translateX(-5px);
  }

  92% {
    -webkit-transform: translateX(5px);
  }

  0%,
  100% {
    -webkit-transform: translateX(0);
  }
}

@media (min-width: 460px) {
  .step-img {
    max-width: 700px;
  }

  .steps {
    .step {
      width: 160px;
      font-size: 22px;
    }
  }
}
</style>

<script>
import { mapState } from "vuex";
import Payment from "../components/Payment.vue";
import SIGN_UP from "../graphql/SignUp.gql";
import GET_PAYMENT_PLAN from "../graphql/GetPaymentPlan.gql";
import UPDATE_ACCOUNT from "../graphql/UpdateAccount.gql";
import GET_COUPON from "../graphql/GetCoupon.gql";
import router from "../router";

export default {
  name: "SignUp",
  components: {
    Payment,
  },
  data() {
    return {
      gapPlusAcknowledge: false,
      isLoaded: false,
      plans: null,
      requestedPlan: null,
      isSubmitting: false,
      isInit: false,
      isSignIn: false,
      upgrade: false,
      token: null,
      chargeCard: null,
      selectedPlanId: "1",
      activeTab: 0,
      coupon: null,
      couponCode: null,
      couponCodeId: null,
      getCodeError: null,
      tabs: [
        { _id: 1, name: "Register" },
        { _id: 2, name: "Payment" },
      ],

      email: "",
      firstName: "",
      lastName: "",
      password: "",
      newPassword2: "",
      phone: "",
      trainingUser: null,
      //  account
      selectedPlan: "Individual Learner",
      selectedAddOn: null,
      skipAddOnCheck: false,
      showAddOnCheck: false,
      signupError: null,
      hasSignupError: null,
      show: true,
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
      ],
      benefits: [
        {
          planIds: ["1", "2", "3", "4"],
          value: "6-8 Hours of sales training",
        },
        {
          planIds: ["1", "2", "3", "4"],
          value: "Interactive learning",
        },
        {
          planIds: ["2", "3", "4"],
          value: "Choose your own journey",
        },
        {
          planIds: ["3", "4"],
          value: "Organizational Reporting",
        },
        {
          planIds: ["4"],
          value: "Regional Reporting",
        },
        {
          planIds: ["2", "3", "4"],
          value: "Team Reporting",
        },
        {
          planIds: ["4"],
          value: "Organizational Chart",
        },
      ],
      planDetails: [
        {
          order: "1",
          planId: "1",
          includes: ["Gap Selling Online Training", "Adaptive Learning"],
          notIncludes: ["Gap Selling Masterclass Library", "Access to Live Events"],
        },
        {
          order: "2",
          planId: "po_internal_gap",
          includes: [
            "Gap Selling Online Training",
            "Gap Selling Masterclass Library",
            "Access to Live Events",
          ],
        },
        {
          order: "3",
          planId: "gap_plus_individual_learner",
          includes: [
            "Gap Selling Online Training",
            "Gap Selling Masterclass Library",
            "Access to Live Events",
          ],
        },
        {
          order: "4",
          planId: "po_internal",
          includes: ["Gap Selling Online Training", "Adaptive Learning", "Team reports"],
          notIncludes: ["Gap Selling Masterclass Library", "Access to Live Events"],
        },
      ],
    };
  },
  computed: mapState(["auth"]),
  async mounted() {
    const self = this;
    // if (!this.$route.query || (this.$route.query && Object.keys(this.$route.query).length === 0)) {
    //   window.location.href = `https://salesgrowth.com/gap-selling-course-pricing/`;
    // }
    const planId = this.$route.query.plan;
    this.requestedPlan = planId;
    this.selectedPlanId = planId ? planId : "1";
    if (planId) {
      this.activeTab = 1;
    }
    await this.getPaymentPlans();

    this.populateForm();
  },
  methods: {
    getPlanDetails(planId) {
      const detail = this.planDetails.find((x) => x.planId == planId);
      return detail ? detail : { includes: [], notIncludes: [] };
    },
    addGapPlus() {
      this.skipAddOnCheck = true;
      // this.selectedPlanId = "5";
      this.setAddOn(this.selectedPlan, true);
      this.onSubmit({ currentTarget: { id: "payment" }, target: { id: "payment" } });
      this.showAddOnCheck = false;
    },
    skipGapPlus() {
      this.skipAddOnCheck = true;
      this.onSubmit({ currentTarget: { id: "payment" }, target: { id: "payment" } });
      this.showAddOnCheck = false;
    },
    setAddOn(selectedPlan, isGapPlus) {
      if (isGapPlus && selectedPlan && selectedPlan.addOnIds && selectedPlan.addOnIds.length > 0) {
        let addon = this.plans.find((x) => x._id == selectedPlan.addOnIds[0]);

        if (addon) {
          this.selectedAddOn = addon;
        }
      } else {
        this.selectedAddOn = null;
      }
    },
    goToPlan(plan) {
      if (plan.action == "contact_sales") {
        window.open(
          `https://salesgrowth.com/gap-selling-online-training-contact?plan=${plan.planId}`
        );
      } else {
        window.location.href = `/sign-up?plan=${plan.planId}`;
      }
    },
    getBenefits(selectedPlan) {
      if (selectedPlan && selectedPlan.planId) {
        console.log("selectedPlan.planId", selectedPlan.planId);
        let benefits = this.benefits.filter((x) => x.planIds.indexOf(selectedPlan.planId) > -1);
        return benefits;
      }
    },
    async getPaymentPlans() {
      return new Promise(async (resolve, reject) => {
        const plansResult = await this.$apollo.query({
          query: GET_PAYMENT_PLAN,
          fetchPolicy: "no-cache",
        });
        // console.log(plansResult.data.paymentPlan);
        if (plansResult && plansResult.data && plansResult.data.paymentPlan) {
          this.plans = plansResult.data.paymentPlan;
          // this.plans = plansResult.data.paymentPlan.filter((x) => x.isSelectable);
          const selectedPlan = this.plans.find((x) => x.planId == this.selectedPlanId);

          this.selectedPlan = selectedPlan;

          this.updatedPaymentTerm(this.selectedPlan);
        }

        resolve(this.plans);
      });
    },
    async applyCouponCode(code) {
      try {
        this.coupon = null;
        this.couponCode = null;
        this.getCodeError = null;
        const getCouponResult = await this.$apollo
          .query({
            query: GET_COUPON,
            fetchPolicy: "no-cache",
            variables: {
              couponCode: code,
            },
          })
          .catch((error) => {
            this.getCodeError = error.message
              ? error.message.replace("GraphQL error: ", "")
              : "error.";
          });

        if (getCouponResult && getCouponResult.data && getCouponResult.data.coupon) {
          this.coupon = getCouponResult.data.coupon;
          this.couponCodeId = getCouponResult.data.coupon.id;
          this.couponCode = null;

          if (this.coupon) {
            if (this.coupon.percent_off) {
              this.selectedPlan.paymentAmountTotalDiscounted = Math.floor(
                this.selectedPlan.paymentAmountTotal -
                this.selectedPlan.paymentAmountTotal * (this.coupon.percent_off / 100)
              );
            }
            if (this.coupon.amount_off) {
              this.selectedPlan.paymentAmountTotalDiscounted = Math.floor(
                this.selectedPlan.paymentAmountTotal - this.coupon.amount_off / 100
              );
            }
          }
        }
      } catch (error) {
        console.log("getCoupon error", error);
        this.getCodeError = error;
      }
    },
    tokenUpdatedError() {
      this.isSubmitting = false;
    },
    async updatedPaymentTerm(plan) {
      const thisPlan = this.plans.find((x) => x.planId === plan.planId);
      this.selectedPlan = plan;
      this.selectedPlanId = plan.planId;
      // thisPlan.paymentAmount = plan.paymentAmounts.find(
      //   (x) => x.paymentTerm === plan.paymentTerm
      // ).amount;

      thisPlan.paymentAmountTotal = thisPlan.paymentAmount;

      // console.log(" thisPlan.paymentAmountTotal", thisPlan.paymentAmountTotal);
      this.$forceUpdate();

      //

      // update plan if not user's plan
      if (
        this.auth.authUser?.account?.selectedPlanId != this.requestedPlan &&
        this.auth.authUser?.account?.selectedPlanId == "demo"
      ) {
        // send to payment
        this.activeTab = 2;
      }

      // update plan if not user's plan

      //
    },
    letsGo() {
      // if (
      //   this.auth.authUser?.account?.paymentPlanId == "single_year" ||
      //   this.auth.authUser?.account?.paymentPlanId == "demo"
      // ) {
      router.push({ name: "course-list-type", query: { checkpickup: true } });
      // } else {
      //   router.push({ name: "settings", query: { tab: 1 } });
      // }
    },
    populateForm() {
      if (this.auth && this.auth.authUser) {
        this.activeTab = this.auth.authUser?.account?.signUpActiveStep
          ? this.auth.authUser?.account?.signUpActiveStep
          : 0;

        if (
          this.auth.authUser?.account?.selectedPlanId != this.requestedPlan &&
          this.auth.authUser?.account?.selectedPlanId == "demo"
        ) {
          // send to payment
          this.activeTab = 2;
        }
        const upgradeToPlus = this.$route.query.upgrade_to_plus;
        // upgrade to plus
        if (upgradeToPlus) {
          const userPaymentPlanId = this.auth.authUser?.account?.paymentPlanId;
          const selectedPlanId = this.auth.authUser?.account?.selectedPlanId;
          const userPaymentPlan = this.plans.find((x) => x.planId === selectedPlanId);
          // get the last plan (upgrade_to_plus = true is always the last plan)
          const userPaymentPlanStripePlanId =
            userPaymentPlan.addOnIds[userPaymentPlan.addOnIds.length - 1];
          const userPaymentPlanStripePlan = this.plans.find(
            (x) => x._id === userPaymentPlanStripePlanId
          );
          window.location.href = `/sign-up?plan=${userPaymentPlanStripePlan.planId}&upgrade=true`;
        }

        const upgrade = this.$route.query.upgrade;
        this.upgrade = upgrade || upgradeToPlus;

        if (upgrade) {
          // send to payment
          this.activeTab = 2;
        }

        // if (this.tabs.length + 1 === this.activeTab) {
        //   // router.push({ name: "course-list", query: { checkpickup: true } });
        //   router.push({ name: "settings", query: { tab: 1 } });
        //   return;
        // }
        const { firmAddress } = this.auth.authUser?.account;
        if (firmAddress) {
          /* eslint no-underscore-dangle: "off" */
          delete firmAddress.__typename;
        }
        this.email = this.auth.authUser?.email;
        this.firstName = this.auth.authUser?.firstName;
        this.middleName = this.auth.authUser?.middleName;
        this.lastName = this.auth.authUser?.lastName;
        this.gender = this.auth.authUser?.gender;
        this.phone = this.auth.authUser?.phone;
        this.dob = this.auth.authUser?.dob;
        //  account
        this.firmName = this.auth.authUser?.account?.firmName;
        this.firmAddress = firmAddress;
        this.firmSuiteNumber = this.auth.authUser?.account?.firmSuiteNumber;
        this.courtFees = this.auth.authUser?.account?.courtFees;
        this.accidentFees = this.auth.authUser?.account?.accidentFees;
        if (this.auth.authUser?.account?.coverageRadius) {
          this.coverageRadius = this.auth.authUser?.account?.coverageRadius;
        }
        // plan
        // this.paymentPlanId = this.auth.authUser?.account?.paymentPlanId;
        // default
        this.paymentTerm = "year";

        this.paymentPlanId = this.auth.authUser?.account?.paymentPlanId;
        this.selectedPlanId =
          this.$route.query && this.$route.query.plan
            ? this.$route.query.plan
            : this.auth.authUser?.account?.selectedPlanId;
        this.paymentTerm = this.auth.authUser?.account?.paymentTerm;
        this.userPaymentPlanId = this.auth.authUser?.account?.userPaymentPlanId;
        // switch (this.paymentPlanId) {
        //   case "5d6684569c55d015c7692ea5":
        //     this.selectedPlan = "company";
        //     this.paymentTerm = "year";
        //     break;
        //   case "5dbef845ad985345f7352d2a":
        //     this.selectedPlan = "team";
        //     this.paymentTerm = "year";
        //     break;
        //   case "5dbef879ad985345f7352d30":
        //     this.selectedPlan = "single";
        //     this.paymentTerm = "year";
        //     break;

        //   default:
        //     break;
        // }
      } else {
        this.$store.dispatch("auth/signin", { authUser: null });
      }
      this.isLoaded = true;
      this.$forceUpdate();
    },
    getAddress() {
      let address;
      if (
        this.auth &&
        this.auth.authUser &&
        this.auth.authUser?.account &&
        this.auth.authUser?.account?.firmAddress
      ) {
        address = `${this.auth.authUser?.account?.firmAddress.street_number} ${this.auth.authUser?.account?.firmAddress.route} ${this.auth.authUser?.account?.firmAddress.locality} ${this.auth.authUser?.account?.firmAddress.administrative_area_level_1} ${this.auth.authUser?.account?.firmAddress.postal_code}`;
      }
      return address;
    },
    back() {
      this.signupError = null;
      this.hasSignupError = null;
      this.activeTab = this.activeTab > 0 ? this.activeTab - 1 : 1;
    },
    setAddress(place) {
      this.firmAddress = place;
      // administrative_area_level_1: 'CO'
      // administrative_area_level_2: undefined
      // country: 'United States'
      // latitude: 40.1696813
      // locality: 'Longmont'
      // longitude: -105.116782
      // postal_code: '80501'
      // route: 'Francis Street'
      // street_number: '429'
    },
    async onSubmit(evt) {
      try {
        evt.preventDefault();
      } catch (error) { }

      window.scrollTo(0, 0);
      let payResult;
      if (this.$refs.form) {
        const isValid = this.$refs.form.validate();

        if (!isValid) {
          return;
        }
      }

      if (this.password != this.newPassword2 && evt.currentTarget.id === "profile") {
        alert("Passwords do not match");
        return;
      }
      let selectedPlanId;
      if (this.selectedAddOn) {
        selectedPlanId = this.selectedAddOn.planId;
      } else {
        selectedPlanId = this.selectedPlanId;
      }

      const thisPlan = this.plans.find((x) => x.planId === selectedPlanId);

      let userPaymentPlanId = null;

      this.selectedPlanDetails = {
        paymentPlanId:
          thisPlan.stripePlans.length > 1
            ? thisPlan.stripePlans.find((x) => x.isDefault).id
            : thisPlan.stripePlans[0].id,
        paymentTerm: thisPlan.paymentTerm,
        userPaymentPlanId: userPaymentPlanId,
        // addOnIds: this.selectedAddOn ? [this.selectedAddOn._id] : null,
      };

      let token;
      // if (evt.currentTarget.id === "plan") {
      //   if (this.selectedPlan == "Individual Learner") {
      //     // inc step
      //     this.activeTab += 1;
      //   } else if (this.selectedPlan == "Team") {
      //     var win = window.open(
      //       "https://info.asalesguyconsulting.com/gapsellingonlinetrainingteam",
      //       "_blank"
      //     );
      //   } else if (this.selectedPlan == "Small Company") {
      //     var win = window.open(
      //       "https://info.asalesguyconsulting.com/gapsellingonlinetrainingcompany",
      //       "_blank"
      //     );
      //   } else {
      //     var win = window.open(
      //       "https://info.asalesguyconsulting.com/gapsellingonlinetrainingcompany-0",
      //       "_blank"
      //     );

      //     // "Large Company"
      //   }
      //   if (window.analytics) {
      //     window.analytics.track("User Seleted Plan", {
      //       plan: this.selectedPlan,
      //       selectedPlanDetails: this.selectedPlanDetails,
      //     });
      //   }

      //   return;
      // }
      if (evt.currentTarget.id === "payment") {
        this.isSubmitting = true;
        // this.chargeCard = true;
        if (!this.token || (this.token && !this.token.plaidPayment)) {
          try {
            payResult = await this.$refs.payment.pay();
            if (payResult && payResult.token) {
              token = payResult.token;
            }
            // console.log("payResult", payResult);
          } catch (error) {
            // error. Stop next
            console.log("error", error);
            return;
          }
        } else {
          token = this.token;
        }
        if (
          this.selectedPlanId == "1" &&
          !this.selectedAddOn &&
          !this.skipAddOnCheck &&
          !this.upgrade
        ) {
          this.skipAddOnCheck = true;
          this.showAddOnCheck = true;
          return;
        }
      }
      this.signupError = null;
      this.hasSignupError = null;
      if (!this.auth || (this.auth && !this.auth.authUser)) {
        try {
          const signupResult = await this.$apollo
            .mutate({
              mutation: SIGN_UP,
              variables: {
                email: this.email,
                trainingUser: this.trainingUser,
                password: this.password,
                firstName: this.firstName,
                lastName: this.lastName,
                phone: this.phone,
                paymentPlanId: this.selectedPlanDetails.paymentPlanId,
                paymentTerm: this.selectedPlanDetails.paymentTerm,
                userPaymentPlanId: this.selectedPlanDetails.userPaymentPlanId,
                selectedPlanId: selectedPlanId,
              },
            })
            .catch((error) => {
              this.hasSignupError = true;
              this.isSubmitting = false;
              this.signupError = error.message
                ? error.message.replace("GraphQL error: ", "")
                : "Sign up error.";
            });
          this.isSubmitting = false;
          const authUser = Object.assign({}, signupResult.data.createAccountAndUser);
          if (authUser?.account?.selectedPlanId == "demo") {
            authUser.account.signUpActiveStep = 2;
          }
          this.$store.dispatch("auth/signup", {
            authUser: authUser,
          });
          // track
          if (window.analytics) {
            window.analytics.identify(authUser.email, {
              name: authUser.firstName + " " + authUser.lastName,
              email: authUser.email,
              plan: this.selectedPlanDetails.paymentPlanId,
            });
            window.analytics.track("User Sign Up", {
              authUser_id: authUser._id,
              email: authUser.email,
              plan: this.selectedPlanDetails.paymentPlanId,
            });
            // window.analytics.page("UserSignUpThankYou", {
            //   title: "User Sign Up Thank You",
            //   url: window.location.href + "-sign-up-thank-you",
            //   path: this.$route.path + "-sign-up-thank-you"
            // });

            window.analytics.page(
              "UserSignUpThankYou " +
              thisPlan.name.split(" ").join("_").toLowerCase() +
              "-register-ty",
              {
                title: "User Sign Up Thank You " + thisPlan.name,
                url:
                  window.location.href +
                  "/" +
                  thisPlan.name.split(" ").join("_").toLowerCase() +
                  "-register-ty",
                path:
                  this.$route.path +
                  "/" +
                  thisPlan.name.split(" ").join("_").toLowerCase() +
                  "-register-ty",
              }
            );

            // [
            //   { name: "path", value: this.$route.path + "-sign-up-thank-you" },
            // ]);
          }
          // end track
          if (authUser.account?.selectedPlanId == "demo") {
            this.activeTab = 3;
          } else {
            this.activeTab = signupResult.data.createAccountAndUser.account?.signUpActiveStep;
          }
        } catch (error) {
          console.log("error");
          this.isSubmitting = false;
        }
      } else {
        //  update account
        try {
          //  find the items to update
          let updateObj;
          switch (evt.target.id) {
            case "payment":
              this.isSubmitting = true;
              updateObj = {};
              if (token.card) {
                token.card.metadata = token.card.metadata
                  ? JSON.stringify(token.card.metadata)
                  : null;
                //  delete props not in gql schema
                delete token.card.wallet;
                //  end delete props not in gql schema

                updateObj.payment = token;
              } else if (token.plaidPayment) {
                updateObj.plaidPayment = token.plaidPayment;
              }
              updateObj.selectedPlanId = selectedPlanId;
              (updateObj.paymentPlanId =
                thisPlan.stripePlans.length > 1
                  ? thisPlan.stripePlans.find((x) => x.isDefault).id
                  : thisPlan.stripePlans[0].id),
                (updateObj.paymentTerm = thisPlan.paymentTerm);
              // updateObj.addOnIds = this.selectedAddOn ? [this.selectedAddOn._id] : null;
              break;
            // case 'profile':
            //   updateObj = {
            //     email: this.email,
            //     password: this.password,
            //     firstName: this.firstName,
            //     middleName: this.middleName,
            //     lastName: this.lastName,
            //     gender: this.gender,
            //     phone: this.phone,
            //     dob: this.dob
            //   };
            //   break;
            case "plan":
              let userPaymentPlanId = null;

              updateObj = {
                paymentPlanId:
                  thisPlan.stripePlans.length > 1
                    ? thisPlan.stripePlans.find((x) => x.isDefault).id
                    : thisPlan.stripePlans[0].id,
                paymentTerm: thisPlan.paymentTerm,
                userPaymentPlanId: userPaymentPlanId,
                selectedPlanId: this.selectedPlanId,
                addOnIds: this.selectedAddOn ? [this.selectedAddOn._id] : null,
              };
              break;

            default:
              break;
          }

          // inc step
          updateObj.signUpActiveStep = this.activeTab + 1;

          if (this.couponCodeId) {
            updateObj.couponCodeId = this.couponCodeId;
          }
          const signupResult = await this.$apollo
            .mutate({
              mutation: UPDATE_ACCOUNT,
              variables: {
                input: updateObj,
              },
            })
            .catch((error) => {
              this.hasSignupError = true;
              this.isSubmitting = false;
              this.signupError = error.message
                ? error.message.replace("GraphQL error: ", "")
                : "Update Account Error.";
            });
          this.isSubmitting = false;
          this.$store.dispatch("auth/updateAccount", {
            account: signupResult.data.updateAccount,
          });

          this.activeTab = signupResult.data.updateaccount?.signUpActiveStep;

          if (this.activeTab == 3 && payResult) {
            if (window.analytics) {
              window.analytics.track("User Paid", {
                payResult: payResult,
                authUser_id: this.auth.authUser._id,
                email: this.auth.authUser.email,
              });

              window.analytics.page(
                "ThankYou " + thisPlan.name.split(" ").join("_").toLowerCase() + "-paid-ty",
                {
                  title: "User Sign Up Thank You " + thisPlan.name,
                  url:
                    window.location.href +
                    "/" +
                    thisPlan.name.split(" ").join("_").toLowerCase() +
                    "-paid-ty",
                  path:
                    this.$route.path +
                    "/" +
                    thisPlan.name.split(" ").join("_").toLowerCase() +
                    "-paid-ty",
                }
              );
            }
          }

          if (this.upgrade) {
            window.location.href = `/sign-up?action=go`;
          } else {
            this.populateForm();
          }
        } catch (error) {
          console.log("error");
          this.isSubmitting = false;
        }
      }
      // if (this.tabs.length + 1 === this.activeTab) {
      //   // router.push({ name: "course-list" });
      //   router.push({ name: "settings", query: { tab: 1 } });
      // }
      // scroll to top
      window.scrollTo(0, 0);
      this.isSubmitting = false;
    },
    toCurrency(currencyString) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return formatter.format(currencyString);
    },
  },
};
</script>
